import { ChangeDetectorRef, Component } from '@angular/core';
import {
  MenuController,
  ModalController,
  NavController,
  Platform
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService, ViewService } from "common-lib";
import {ApiService} from "../services/api.service";
import {DataService} from "../services/data.service";
import {ChangeUrlPage} from "./change-url/change-url.page";
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import {environment} from '../environments/environment'

import * as moment from "moment";
import {SwService} from '../services/sw.service';
declare let $: any;

declare let firebase: any;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  navigate: any[] = [
    {
      title : "Chat",
      id: 'chat',
      url   : "/whatsapp",
      src  : "assets/imgs/bubble-chat.svg",
    },
    {
      title : "Participants",
      url   : "/home",
      id: 'participants',
      src  : "assets/imgs/diversity.svg",
    },
    {
      title : "Organizations",
      id: 'organizations',
      url   : "/organizations",
      src  : "assets/imgs/hierarchy.svg",
    },
    {
      title : "Supporto tecnico",
      id: 'supporto-tecnico',
      url   : "/supporto-tecnico",
      src  : "assets/imgs/customer-service.svg",
    },
    {
      title : "Gestione tracker",
      src  : "assets/imgs/router.svg",
      show_sub: false,
      sub_menu: [
        {
          title: "Tutti i tracker",
          url: "/trackers",
          id: 'trackers',
          src: "assets/imgs/router.svg",
        },
        {
          title : "Controlli",
          url: "/controllo-trackers",
          id: 'controllo-trackers',
          src  : "assets/imgs/checklist.svg",
        },
        {
          title : "Inserimento FOTA",
          id: 'stock-insert',
          url: "/inserimento-magazzino-fota",
          src: "assets/imgs/conveyor.svg",
        },
        {
          title: "Magazzino wi-tek",
          id: 'stock-witek',
          url: "/stock-witek",
          src: "assets/imgs/warehouse.svg",
        },
        {
          title: "Magazzino Amazon",
          id: 'stock-amazon',
          url: 'stock-amazon',
          src: "assets/imgs/pallet.svg",
        },
        {
          title: "Comandi a sim",
          id: 'command-to-sim',
          url: "/command-to-sim",
          src: "assets/imgs/sim-card.svg",
        },
        {
          title: "Comandi a tanti tracker",
          id: 'command-to-many-trackers',
          url: "/command-to-many-trackers",
          src: "assets/imgs/checklist.svg",
        },
      ]
    },
    {
      title: "Controllo pagamenti",
      id: 'check-payments',
      url: "/check-payments",
      src: "assets/imgs/police.svg",
    },
    {
      title : "Ordini",
      show_sub: false,
      src: "assets/imgs/orders-list.svg",
      sub_menu: [
        {
          title: "Tutti gli ordini",
          id: 'adv',
          url: "/orders",
          src: "assets/imgs/delivery.svg"
        },
        {
          title : "Ordini archiviati",
          id: 'adv',
          url: "/orders-archivied",
          src: "assets/imgs/archive.svg"
        },
        {
          title: "Sostituzioni",
          url: "/tracker-replace",
          id: 'adv',
          src: "assets/imgs/replace.svg",
        },
      ],
    },
    {
      title: "Gestione ADV",
      id: 'adv',
      url: "/adv",
      src: "assets/imgs/adwords.svg",
    },
    {
      title: "Utilities",
      src: "assets/imgs/repair.svg",
      show_sub: false,
      sub_menu: [
        {
          title: "Codici sconto Amazon",
          id: 'codici-sconto-amazon',
          url: "/codici-sconto-amazon",
          src: "assets/imgs/gift-card.svg",
        },
        {
          title: "Landing pages",
          id: 'landing-pages',
          url: "/landing-pages",
          src: "assets/imgs/magnet.svg",
        },
        {
          title: "Marketing",
          id: 'marketing',
          url: "/marketing",
          src: "assets/imgs/start-up.svg",
        },
        {
          title: "Statistiche",
          id: 'analytics',
          url: "/analytics",
          src: "assets/imgs/data-analytics.svg",
        },
        {
          title: "Invio SMS",
          id: 'sms',
          url: "/sms-to-phone",
          src: "assets/imgs/sim-card.svg",
        },
        {
          title: "Imei tracker per utente",
          id: 'get-tracker-by-email',
          url: "/get-tracker-by-email",
          src: "assets/imgs/checklist.svg",
        }
      ]
    },
    {
      title: "Migrazione",
      src: "assets/imgs/router.svg",
      show_sub: false,
      sub_menu: [
        {
          title: "Programmazione dispositivi",
          url: "/reinvio-programmazione",
          id: 'reinvio-programmazione',
          src: "assets/imgs/router.svg",
        }
      ]
    },
    {
      title : "Vecchie pagine",
      src  : "assets/imgs/router.svg",
      show_sub: false,
      sub_menu: [
        {
          title: "Tutti i tracker",
          url: "/trackers",
          id: 'old_trackers',
          src: "assets/imgs/router.svg",
        },
        {
          title : "Organizations",
          id: 'old_organizations',
          url   : "/organizations",
          src  : "assets/imgs/hierarchy.svg",
        },
        {
          title : "Participants",
          url   : "/home",
          id: 'old/participants',
          src  : "assets/imgs/diversity.svg",
        }
      ]
    },
    {
      title: "API url",
      id: 'api-url',
      src: "assets/imgs/url.svg",
    },
    {
      title: "Logout",
      id: 'logout',
      src: "assets/imgs/exit.svg",
    },
  ];
  
  listener = {
    view: null,
  };
  
  isDev: boolean;
  logged_email: string;
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private api: ApiService,
    private auth: AuthService,
    private navController: NavController,
    public data: DataService,
    private modalController: ModalController,
    private cd: ChangeDetectorRef,
    private view: ViewService,
    private router: Router,
    private storage: Storage,
    private menu: MenuController
  ) {
    this.listenerView();
    this.initializeApp();
  }

  async ngOnInit() {}
  
  initializeApp() {
    this.platform.ready().then(async () => {
      // Initialize Firebase
      firebase.initializeApp(environment.firebaseConfig);
      
      this.initDataTableUtility();
      await this.storage.create();
      this.auth.initialization(firebase);
      this.checkProdDevMod();
      await this.api.setModality(environment.baseUrl, environment.baseUrlCommunication, environment.baseUrlMigration);
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      AuthService.authStateChange.subscribe(async (loggedIn: boolean) => {
        if (loggedIn) {
          try{
            this.data.initialize(await this.api.getLogin());
            this.logged_email = AuthService.getEmail();
          }catch(err){
            console.error(err);
            this.auth.logout();
          }
        } else {
          console.log("initializeApp() user not logged in");
          this.router.navigate(['login']);
        }
      });
    });
  }
  
  // Verifica se il progetto di firebase è quello di prod o di dev
  checkProdDevMod(){
    try{
      if(firebase.app().options.projectId === 'white-label-dev-635e0'){
        this.isDev = true;
      }else{
        this.isDev = false;
      }
    }catch(err){
      console.error(err);
    }
  }
  
  async selectItem(item: any){
    if(item.sub_menu && item.sub_menu.length > 0){
      return item.show_sub = !item.show_sub;
    }
    this.menu.close();
    switch (item.id) {
      case 'organizations':
      case 'participants':
        window.open(`${environment.url_admin_nuovo}/home`);
        break;
      case 'trackers':
        window.open(`${environment.url_admin_nuovo}/trackers`);
        break;
      case 'chat':
        window.open(item.url);
        break;
      case 'invoice':
      case 'params':
      case 'stock-amazon':
        this.router.navigate([item.url]);
        break;
      case 'api-url':
        const modal = await this.modalController.create({
          component: ChangeUrlPage,
        });
        return await modal.present();
      case 'logout':
        this.auth.logout();
        break;
      default:
        if(item.url){
          this.navController.navigateRoot(item.url);
        }
    }
  }
  
  // * listener
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view obj", obj)
      // this.zone.run(() => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      // this.cd.detectChanges();
      setTimeout(() => {
        this.cd.markForCheck();
        // this.cd.detectChanges();
      }, 250);
      // });
    });
  }
  
  /** Inizializza tutte le utility delle dataTables **/
  private initDataTableUtility(){
    
    /** Per date formato 12:30 lun 21/05/1990 **/
    $.fn.dataTable.ext.type.order['date-hhmm-ddd-ddmmyyyy-pre'] = (date: string) => {
      let new_date: string = date.slice(0, 6);
      new_date += date.slice(10, date.length);
      return moment(new_date, 'HH:mm DD/MM/YYYY').unix().toString();
    };
  
    /** Per date formato 21/05/1990 - 12:30:00 **/
    $.fn.dataTable.ext.type.order['date-ddmmyyyy-hhmmss-pre'] = (date: string) => {
      let new_date: string = date.slice(0, 10);
      new_date += date.slice(12, date.length);
      return moment(new_date, 'DD/MM/YYYY HH:mm:ss').unix().toString();
    };
  
    /** Per date formato standard (es 21/09/2023 **/
    $.fn.dataTable.ext.type.order['date-standard-pre'] = (date: string) => {
      try{
        return moment(date, 'DD/MM/YYYY').unix().toString();
      }catch(err){
        return date;
      }
    };
  
    /** Per croppare campi stringa molto lunghi **/
    $.fn.dataTable.render.ellipsis = ((cutoff: number) => {
      return (data, type) => {
        try{
          return type === 'display' && data?.length > cutoff ? data.substr( 0, cutoff ) +'…' : data;
        }catch(err){
          return data;
        }
      }
    });
  }
  
}
