import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from "@angular/common/http";
import {AuthService, PositionGps} from "common-lib";
import {Storage} from "@ionic/storage-angular";
import {group} from '@angular/animations';
import {TicketStatus} from '../classes/ticket';
import {environment} from '../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ApiService{

  static firebase: any;

  // baseUrl: string = 'https://localizzazione-smartphone.oa.r.appspot.com';
  // baseUrl: string = 'https://white-label-dev-635e0.ey.r.appspot.com';
  // baseUrl: string = "https://api.balin.app";
  // baseUrl: string = 'https://api-dev.balin.app';
  baseUrl: string = 'https://api.balin.app';
  // baseUrl: string = 'https://api.495318.net';
  
   baseUrlCommunication: string = "http://localhost:8084";
  //baseUrlCommunication: string = "https://api.balin.app";

  baseUrlMigration: string = "http://localhost:8085";
  isDevelopment: boolean = false;
  
  static fota_api_key: string;
  static fota_api_url: string;
  static fota_company_id: string;
  
  
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private auth: AuthService
  ) {}

  /** Imposta e verifica l'API URL **/
  async setModality(url: string, urlCommunication: string, urlMigration: string){
    try{
      this.baseUrl = url;
      this.baseUrlCommunication = urlCommunication;
      this.baseUrlMigration = urlMigration;
      // await this.storage.ready();
      let baseUrl: string = await this.storage.get("baseUrl") as string;
      if(baseUrl){
        this.isDevelopment = true;
        this.baseUrl = baseUrl;
      }
      let baseUrlCommunication: string = await this.storage.get("baseUrlCommunication") as string;
      if(baseUrlCommunication){
        this.isDevelopment = true;
        this.baseUrlCommunication = baseUrlCommunication;
      }
      console.log(this.baseUrl, this.baseUrlCommunication);
    }catch(err){
      console.error(err);
    }
  }

  /**
   * Setta l'header con le credenziali volute
   * @param {string} username
   * @param {string} password
   * @returns {HttpHeaders}
   */
  private setHeaders(username: string, password: string): HttpHeaders{
    return new HttpHeaders().set('Authorization', 'Basic ' + btoa(username + ':' + password));
  }

  /**
   * Setta l'header prendendo ogni volta il token di firebase (che nel tempo potrebbe scadere)
   * @returns {Observable<any>}
   */
  private getFirebaseHeader(){
    return new Promise((resolve, reject) => {
      this.auth.getIdToken().then((token: string) => {
        return resolve(<HttpHeaders> this.setHeaders(AuthService.firebase.auth().currentUser.email, token));
      }).catch((err: any) => {
        return reject(err);
      });
    });
  }

  getLogin(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/login', options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getPartecipant(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/partecipant', options).subscribe((res: any) => {
          console.log(res);
          resolve(res?.data);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getSingleParticipant(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/partecipant/' + id, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchParticipant(idParticipant: string, data: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/partecipant/' + idParticipant, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchParticipantTesting(idParticipant: string, data: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(this.baseUrl + '/admin/testing/partecipant/' + idParticipant, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchResetFlagAllarmi(idParticipant: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(this.baseUrl + '/admin/testing/reset_flag_alarm/partecipant/' + idParticipant, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getTrackers(skip: number, limit: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/trackerGps?limit=' + limit + "&skip=" + skip, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  deleteTrackerGps(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.delete(`${this.baseUrl}/admin/trackerGps/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTrackersPartecipantOrganization(id_organization: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/participant/trackers/of_organization/${id_organization}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTrackersOrganization(id_organization: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/trackers/of_organization/${id_organization}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getTracker(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/trackerGps/' + id, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTrackerByImei(imei: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/trackerGps/imei/' + imei, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  postTracker(platform_type: number, type_trackerGps: number, imei: string, serial_number: string, type_sim: number, simStatus: number, lotto_magazzino?: string, sim_id?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          platform_type: platform_type,
          type_trackerGps: type_trackerGps,
          imei: imei,
          serial_number: serial_number,
          type_sim: type_sim,
          simStatus: simStatus,
          lotto_magazzino: lotto_magazzino,
          sim_id: sim_id
        };
        this.http.post(this.baseUrl + '/admin/trackerGps/inserisci', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchTracker(idTracker: string, note: any[]){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          note: note
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchTrackerMagazzino(idTracker: string, magazzino_trackerGps: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          magazzino_trackerGps: magazzino_trackerGps
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/magazzino_trackerGps/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchTrackerSimStatus(idTracker: string, simStatus: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          simStatus: simStatus
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/simStatus/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchTrackerDistruggi(idTracker: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {};
        this.http.patch(this.baseUrl + '/admin/trackerGps/distruggi/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchTrackerStatus(idTracker: string, status_trackerGps: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          status_trackerGps: status_trackerGps
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/change_status_trackerGps/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchTrackerFormatta(idTracker: string, platformType: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          platform_type: platformType
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/formatta/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  patchTrackerSostituisci(idTracker: string, imeiNewTrackerGps: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          imeiNewTrackerGps: imeiNewTrackerGps
        };
        this.http.patch(this.baseUrl + '/admin/trackerGps/sostituisci/' + idTracker, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postTrackerManualActivation(imei_tracker: string, id_organization: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          imei_tracker: imei_tracker,
          id_organization: id_organization
        };
        this.http.post(`${this.baseUrl}/admin/tracker/manual_activation/`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCommandTrackerGps(type: number, type_trackerGps: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/commandTrackerGps_model?type=${type}&type_trackerGps=${type_trackerGps}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getCommand(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/commandTrackerGps/' + id, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCommandImei(imei: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + `/admin/commandTrackerGps/single_command?imei=${imei}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  postSingleCommand(command: string, noResponse: boolean, answerExpected: string, imei: string, maxRetry: number, notifyOnSuccess?: boolean, notifyOnError?: boolean, email?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          arrayCommand: [{command: command, answer_expected: answerExpected, noResponse: noResponse}],
          imei: imei,
          max_retry: maxRetry,
          notifyOnSuccess: notifyOnSuccess,
          notifyOnError: notifyOnError,
          emails: [email]
        };
        this.http.post(this.baseUrl + '/admin/commandTrackerGps/2', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  postConfiguration(id_commandTrackerGps_model: string, imei: string, maxRetry: number, notifyOnSuccess?: boolean, notifyOnError?: boolean, email?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          id_commandTrackerGps_model: id_commandTrackerGps_model,
          imei: imei,
          max_retry: maxRetry,
          notifyOnSuccess: notifyOnSuccess,
          notifyOnError: notifyOnError,
          emails: [email]
        };
        this.http.post(this.baseUrl + '/admin/commandTrackerGps/1', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getCommandConfiguration(skip: number, limit: number, typeTrackerGps: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + '/admin/commandTrackerGps_model?limit=' + limit + '&skip=' + skip + '&type_trackerGps=' + typeTrackerGps + '&type=1';
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getPositions(imei: string, skip: number, limit: number, timestampStop: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = this.baseUrl + '/admin/positionGps?imei=' + imei + '&limit=' + limit + '&skip=' + skip + '&timestampStart=0&timestampStop=' + timestampStop;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postTokenFCM(idPartecipant: string, idDevice: string, token: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idPartecipant: idPartecipant,
          idDevice: idDevice,
          token: token
        };
        this.http.post((forcedUrl || this.baseUrlCommunication) + '/communication/testPlatform/tokenFCM', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  deleteTokenFcm(idToken: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.delete((forcedUrl || this.baseUrlCommunication) + '/communication/testPlatform/tokenFCM/' + idToken, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAllTokensFCM(idParticipant: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get((forcedUrl || this.baseUrlCommunication) + '/communication/testPlatform/tokenFCM/getAllTokensFCM?idParticipant=' + idParticipant, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testSpeedAlarm(idParticipant: string, speed: number, emails: string[], participants: string[], language: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          speed_alarm_value: speed,
          speed_alarm_email: emails,
          speed_alarm_partecipant: participants,
          language: language
        };
        this.http.post((forcedUrl || this.baseUrl) + '/testPlatform/notification/speedAlarm', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testUnplugAlarm(idParticipant: string, emails: string[], participants: string[], language: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          unplug_alarm_email: emails,
          unplug_alarm_partecipant: participants,
          language: language
        };
        this.http.post((forcedUrl || this.baseUrl) + '/testPlatform/notification/unplugAlarm', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testMovementAlarm(idParticipant: string, emails: string[], participants: string[], language: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          movement_alarm_email: emails,
          movement_alarm_partecipant: participants,
          language: language
        };
        this.http.post((forcedUrl || this.baseUrl) + '/testPlatform/notification/movementAlarm', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testParkingAlarm(idParticipant: string, emails: string[], participants: string[], language: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          parking_alarm_email: emails,
          parking_alarm_partecipant: participants,
          language: language
        };
        this.http.post((forcedUrl || this.baseUrl) + '/testPlatform/notification/parkingAlarm', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testOfflineAlarm(idParticipant: string, value: number, emails: string[], participants: string[], language: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          offline_alarm_value: value,
          offline_alarm_email: emails,
          offline_alarm_partecipant: participants,
          language: language
        };
        this.http.post((forcedUrl || this.baseUrl) + '/testPlatform/notification/offlineAlarm', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testPoiAlarm(idParticipant: string, idPoi: string, onEntry: boolean, emails: string[], participants: string[], language: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          idPoi: idPoi,
          onEntry: onEntry,
          poi_alarm_email: emails,
          poi_alarm_partecipant: participants,
          language: language
        };
        this.http.post((forcedUrl || this.baseUrl) + '/testPlatform/notification/poiAlarm', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getPoiOrganization(idOrganization: string, forcedUrl?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get((forcedUrl || this.baseUrlCommunication) + '/communication/testPlatform/getPoi/' + idOrganization, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testPositions(positions: PositionGps[]){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          positions: positions
        };
        this.http.post(this.baseUrl + '/testPlatform/testListPositions', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  testTriggerOfflineAlarm(idParticipant: string, timestamp_position: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          idParticipant: idParticipant,
          timestamp_isOnline: timestamp_position
        };
        this.http.post(this.baseUrl + '/testPlatform/testAlarmOffline', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchInvoice(id_invoice: string, notaCredito?: boolean, notaCreditoNumber?: string, isInsert?: boolean) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          notaCredito: notaCredito,
          notaCreditoNumber: notaCreditoNumber,
          isInsert: isInsert
        };
        this.http.patch(`${this.baseUrl}/admin/fattura_elettronica/${id_invoice}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postInvoiceBankTransfert(id_invoice: string, id_organization: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          invoice_id: id_invoice,
          id_organization: id_organization
        };
        console.log(data);
        this.http.post(`${this.baseUrl}/admin/stripe/invoice/pay/bank_transfer/recived`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  // * organization
  
  getOrganization(id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/organization/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAdminActivitiesToDo(skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/admin/activity?limit=${limit}&skip=${skip}&type_activity=3&isDo=false`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOrganizationWithTrackerToDeactivate() {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/controlli_amministrativi/controllo_6`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOrganizations(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/organization', options).subscribe((res: any) => {
          console.log(res)
          resolve(res?.data);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getInvoicesToEmitCreditNote(skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/controlli_amministrativi/controllo_7?limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getInvoicesWithFatture24Error(skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/controlli_amministrativi/controllo_8?limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchOrganizationPauseSubscription(id: string, sospendi: boolean) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          sospendi: sospendi,
        };
        this.http.patch(this.baseUrl + '/admin/organization/pause_subscription/' + id, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchOrganizationSendLevelCommunication({
    ids,
    body,
  }: {
    ids: string[]
    body: {
      level: number
      id_organizations?: string[]
    }
  }) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        body.id_organizations = ids;
        this.http.patch(`${this.baseUrl}/admin/organization/sendLevelCommunication/`, body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  postCreateBankTransfers(to_create: {id_organization: string, invoice_ids: string[]}[], notification_type?: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.post(`${this.baseUrl}/admin/bank_transfers/request/`, {organizations: to_create, notification_type}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postBankTransfersReminder(to_remind: {id_organization: string, invoice_ids: string[]}[]) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.post(`${this.baseUrl}/admin/bank_transfers/reminder`, {organizations: to_remind}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // * twilio: sim
  
  getSimTwilio(iccid: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim_twilio/${iccid}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getSimsTwilio(page_size: number, next_page_number?: number, next_page_token?: string, status?: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}/admin/sim_twilio?PageSize=${page_size}`;
        if(next_page_number && next_page_token) url += `&Page=${next_page_number}&PageToken=${next_page_token}`;
        if(status) url += `&Status=${status}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchSimTwilio(iccid: string, body: any, ratePlan?: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const patchBody = {
          friendlyName: body.friendlyName,
          status: body.status,
          ratePlan: ratePlan
        };
        
        this.http.patch(
          `${this.baseUrl}/admin/sim_twilio/${iccid}`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  // * twilio: command
  
  getTwilioCommandMultiple({
    iccid, limit, skip
  }: {
    iccid: string,
    limit: number,
    skip?: number
  }) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim_twilio/command/${iccid}?limit=${limit}&skip=${skip}`
          // `${this.baseUrl}/admin/sim_twilio/command/${iccid}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postTwilioCommand(iccid: string, body: any) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const patchBody = {
          command: body.command
        };
        
        this.http.post(
          `${this.baseUrl}/admin/sim_twilio/command/${iccid}`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postSmsToSim(sms_body: string, priority: number, id_admin: string, h_start: number, h_end: number, phones?: string[], iccid?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        const body = {
          phones,
          iccid,
          id_admin,
          sms_body,
          priority,
          h_start,
          h_end
        };
      
        this.http.post(`${this.baseUrl}/admin/sms_to_trackers/`, body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postRetrivePhonesSMS(phones?: string[], iccid?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const body = {
          phones,
          iccid
        };
        
        this.http.post(`${this.baseUrl}/admin/sms_to_trackers/retrive_sms/`, body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getRetriveCheckSmsMachine(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(`${this.baseUrl}/admin/sms_to_trackers/retrive_sms_machine_state/`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // * Altre SIM (Sim normali stile Wind)
  
  getSimCommands(iccid: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/command_sim/${iccid}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  sendSimThinksMobileCommand(iccid: string, command: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          iccid: iccid,
          command: command
        };
        this.http.post(`${this.baseUrl}/admin/command_sim`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  sendSimCommand(iccid: string, command: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          iccid: iccid,
          command: command
        };
        this.http.post(`${this.baseUrl}/admin/command_sim`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getFirebaseUid(email: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        // this.http.post('http://localhost:8080/admin/utility/firebaseUid/', {email: email}, options).subscribe((res: any) => {
        this.http.post(this.baseUrl + '/admin/utility/firebaseUid/', {email: email}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getAdmins(limit?: number, skip?: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrlCommunication}/communication/admin/admin?limit=${
            limit}&skip=${skip}`
          , options
        ).subscribe((res: any) => {
          res["entity"] = "admin";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAdmin(id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/single_admin/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCompanyMultiple(limit?: number, skip?: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/company?limit=${limit || 50}&skip=${skip || 0}`, options).subscribe((res: any) => {
          res["entity"] = "company";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCompany(id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/company/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCompanyContacts(id_company: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/company/contacts/${id_company}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCompanyByOrganization(id_organization: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/company/organization/${id_organization}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getContact(id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/contact/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getGeneralParameter() {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrlCommunication}/communication/admin/utility/general_parameter`
          , options
        ).subscribe((res: any) => {
          res["entity"] = "general_parameter";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  patchHistoryActivity(obj: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        // const body: any = {};
        const body = obj;
        for(let key in body){
          if(body[key] === null) body[key] = undefined;
        }
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/activity/history/${obj._id}`
          , body
          , options
        ).subscribe((res: any) => {
          res["entity"] = "activity";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  deleteHistoryActivity(obj: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        // const body: any = {};
        const body = obj;
        for(let key in body){
          if(body[key] === null) body[key] = undefined;
        }
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/activity/delete/history/${obj._id}`
          , body
          , options
        ).subscribe((res: any) => {
          res["entity"] = "activity";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  patchActivity(obj: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        // const body: any = {};
        const body = obj;
        for(let key in body){
          if(body[key] === null) body[key] = undefined;
        }
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/activity/${obj._id}`
          , body
          , options
        ).subscribe((res: any) => {
          res["entity"] = "activity";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  patchCompany(id: string, obj: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        // const body: any = {};
        const body = obj;
        
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/company/${id}`
          , body
          , options
        ).subscribe((res: any) => {
          res["entity"] = "company";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  patchCompanyOrganization(id: string, idOrganization: string, platform_type: number, type: 'add' | 'remove') {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/company/updateOrganizations/${id}`,
          {type: type, idOrganization: idOrganization, platform_type: platform_type},
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  patchUnionCompanies(id_target: string, id_destination: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/company/union/${id_target}`
          , {destination_idCompany: id_destination}
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  deleteCompany(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.delete(
          `${this.baseUrlCommunication}/communication/admin/company/${id}`,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  getChipsCompany(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/chip_company/`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchUpdateChipCompany(idCompany: string, chip_id: string, added: boolean, idAdmin: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const body: any = {
          chip_id: chip_id,
          added: added,
          idAdmin: idAdmin
        };
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/company/chip_company/${idCompany}`, body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  postActivity(obj: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        // const body: any = {};
        const body = obj;
        
        if (!body.idAdminManager) {
          body.idAdminManager = undefined;
        }
        // if (!body.typeActivity) {
        //   body.typeActivity = undefined;
        // }
        // if (!body.idCompany) {
        //   body.idCompany = undefined;
        // }
        
        for(let key in body){
          if(body[key] === null) body[key] = undefined;
        }
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/activity`
          , body
          , options
        ).subscribe((res: any) => {
          res["entity"] = "activity";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  getCompanyAllActivities(id_company: string, limit: number, skip: number){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        let url: string = `${this.baseUrlCommunication}/communication/admin/company/all_activities/${id_company}?limit=${limit}&skip=${skip}`;
      
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  getActivity(limit: number, skip: number, idCompany?: string, isDo?: boolean, id_organization?: string, type_activity?: number, imei_tracker?: string){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let url: string = `${this.baseUrlCommunication}/communication/admin/activity?limit=${limit}&skip=${skip}`;
        if(idCompany) url += `&idCompany=${idCompany}`;
        if(id_organization) url += `&id_organization=${id_organization}`;
        if(imei_tracker) url += `&imei_tracker=${imei_tracker}`;
        if(type_activity) url += `&type_activity=${type_activity}`;
        if(isDo || isDo === false) url += `&isDo=${isDo || 'false'}`;
        
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  getActivitiesTickets(limit: number, skip: number){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let url: string = `${this.baseUrlCommunication}/communication/admin/activity/tickets?limit=${limit}&skip=${skip}`;
        
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  deleteActivity(idActivity: string){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let url: string = `${this.baseUrlCommunication}/communication/admin/activity/${idActivity}`;
        
        this.http.delete(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  postCompany(obj: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        // const body: any = {};
        const body = obj;
        
        if (!body.idAdmin) {
          body.idAdmin = undefined;
        }
        if (!body.platform_type) {
          body.platform_type = undefined;
        }
        
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/company`
          , body
          , options
        ).subscribe((res: any) => {
          res["entity"] = "company";
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  refreshAllCompanyData(id_company: string){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(`${this.baseUrlCommunication}/communication/admin/company/get-all-data/${id_company}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  getWhatsappMessage(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/whatsapp_message/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postWhatsappMessage(from: string, to: string, body: string, id_ticket: string, fileName?: string, firebasePath?: string, translation?: any[]) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          from: from,
          to: to,
          body: body,
          id_ticket: id_ticket,
          filename: fileName,
          path_firebase: firebasePath,
          translation: translation,
        };
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/whatsapp_message`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  postWhatsappMessageMeta(from: string, to: string, body: string, id_ticket: string, fileName?: string, firebasePath?: string, translation?: any[], is_template?: boolean, template_name?: string, template_lang_code?: string, fragments?: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          from: from,
          to: to,
          body: body,
          id_ticket: id_ticket,
          filename: fileName,
          path_firebase: firebasePath,
          translation: translation,
          is_template: is_template,
          template_name: template_name,
          template_lang_code: template_lang_code,
          fragments: fragments,
        };
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/cloudApi/message`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  getEmailMessage(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          headers: <HttpHeaders>(await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/email_message/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  
  postNewEmail(email_owner: string, email_customer: string, body: string, subject: string, attachments: {fileName: string, path: string}[], id_ticket?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let data: any = {
          email_owner: email_owner,
          email_customer: email_customer,
          body: body.replace(/\n/g, "<br />"),
          subject: subject,
          attachments: attachments,
          id_ticket: id_ticket
        };
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/new_email_message/`,
          data,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  
  getTickets(limit?: number, skip?: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/ticket?limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getReplaceTrackerTickets(limit?: number, skip?: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/ticket/replace-tracker?limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // Contatti
  getContacts(limit?: number, skip?: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/contact?limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getTicket(id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/ticket/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getSmsMessage(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/sms_message/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postSmsMessage(from: string, to: string, body: string, fileName?: string, firebasePath?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          from: from,
          to: to,
          body: body
        };
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/sms_message/skebby`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  
  getCallsByCompany(id_company: string, limit?: number, skip?: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/company/all_call/${id_company}?limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAllMessagesInTickets(tickets: string[]){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          tickets
        }
        this.http.post(`${this.baseUrlCommunication}/communication/admin/ticket/tickets/all_messages/`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCallByContact(id_contact: string, limit?: number, skip?: number, sort?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/contact/calls?id_contact=${id_contact}&limit=${limit}&skip=${skip}&sort=${sort}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getCallByTicket(id_ticket: string, limit?: number, skip?: number, sort?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/ticket/calls?id_ticket=${id_ticket}&limit=${limit}&skip=${skip}&sort=${sort}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getMessagesByTicket(id_ticket: string, sort?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/ticket/messages/${id_ticket}?sort=${sort}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchCallCountUnread(id_admin: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {};
        
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/admin/read_all_calls/${id_admin}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchCallToRead(id_call: string, to_read: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/twilio/call/toggle_to_read/${id_call}`,
          {to_read},
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  postScontoAmazon(platform_type: number, percentuale: number, asin: string, timestamp_scadenza: number, codice: string, isAvailable: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          platform_type: platform_type,
          percentuale: percentuale,
          timestamp_scadenza: timestamp_scadenza,
          codice_sconto: codice,
          isAvailable: isAvailable,
          asin: asin
        };
        this.http.post(this.baseUrl + '/admin/sconto_amazon/', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchScontoAmazon(id: string, isAvailable: boolean, usato_automaticamente: boolean, note: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          isAvailable: isAvailable,
          usato_automaticamente: usato_automaticamente,
          note: note
        };
        this.http.patch(this.baseUrl + '/admin/sconto_amazon/' + id, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getScontiAmazon(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/sconto_amazon/', options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  deleteScontiAmazon(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.delete(this.baseUrl + '/admin/sconto_amazon/' + id, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getLandingPages(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/sconto_landing_page/', options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  deleteLanding(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.delete(this.baseUrl + '/admin/sconto_landing_page/' + id, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postLanding(platform_type: number, percentuale: number, asin: string, chiave_codice_sconto: string, lang: string, modality: string, array_messaggi_risposta: string[], messaggio_no_sconto: string, note: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          platform_type: platform_type,
          percentuale: percentuale,
          asin: asin,
          chiave_codice_sconto: chiave_codice_sconto,
          lang: lang,
          modality: modality,
          array_messaggi_risposta: array_messaggi_risposta,
          messaggio_no_sconto: messaggio_no_sconto,
          note: note
        };
        this.http.post(this.baseUrl + '/admin/sconto_landing_page/', data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchLanding(id: string, platform_type: number, percentuale: number, asin: string, chiave_codice_sconto: string, lang: string, modality: string, array_messaggi_risposta: string[], messaggio_no_sconto: string, note: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data: any = {
          platform_type: platform_type,
          percentuale: percentuale,
          asin: asin,
          chiave_codice_sconto: chiave_codice_sconto,
          lang: lang,
          modality: modality,
          array_messaggi_risposta: array_messaggi_risposta,
          messaggio_no_sconto: messaggio_no_sconto,
          note: note
        };
        this.http.patch(this.baseUrl + '/admin/sconto_landing_page/' + id, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getContactsLanding(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/contatto_landingpage/', options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchWhatsappNoWhatsappFromCustomer(chat_customer: string, no_whatsapp: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          no_whatsapp: no_whatsapp
        };
        
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/company/whatsapp_chat/fromCustomer/${chat_customer}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  // REFERRAL
  
  getReferrals(skip: number, limit: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(this.baseUrl + '/admin/referral?limit=' + limit + "&skip=" + skip, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getReferral(id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/referral/${id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchReferral(id: string, data: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(`${this.baseUrl}/admin/referral/${id}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // STRIPE
  patchToggleBlockedlogin(idOrganization: string, login_blocked: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          login_blocked: login_blocked
        };
        this.http.patch(`${this.baseUrl}/admin/organization/login_blocked/${idOrganization}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchToggleBalanceRecharge(idOrganization: string, enable_balance_recharge: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          enable_balance_recharge: enable_balance_recharge
        };
        this.http.patch(`${this.baseUrl}/admin/organization/enable_balance_recharge/${idOrganization}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postRechargeCustomerBalance(idOrganization: string, amount: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          amount: amount,
          id_organization: idOrganization
        };
        this.http.post(`${this.baseUrl}/admin/stripe/customer/balance/recharge`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // ADV
  
  getAdvVisitors(skip: number, limit: number, order: string = 'desc') {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/adv_visitor/with_contact?skip=${skip}&limit=${limit}&order=${order}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getSingleAdvVisitor(id_visitor: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/adv_visitor/${id_visitor}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAdvFinalized(skip: number, limit: number, order: string = 'desc') {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/adv_finalized/with_contact?skip=${skip}&limit=${limit}&order=${order}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getSingleAdvFinalized(id_finalized: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/adv_finalized/${id_finalized}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  postAdvVisitorMatchContact(cookie: string, ip: string, id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          cookie: cookie,
          ip_address: ip,
          id: id
        }
        this.http.post(`${this.baseUrl}/admin/adv_visitor/match_contact`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postAdvMatchContactManually(adv_visitor_id: string, id_organization: string, id_company: string, is_archived?: boolean, archived_reason?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          adv_visitor_id: adv_visitor_id,
          id_organization: id_organization,
          id_company: id_company,
          is_archived: is_archived,
          archived_reason: archived_reason
        };
        this.http.post(`${this.baseUrl}/admin/adv_finalized/match_contact_manually`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdvFinalized(id: string, id_organization: string, id_company: string, archived?: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          id_organization: id_organization,
          id_company: id_company,
          archived: archived
        };
        this.http.patch(`${this.baseUrl}/admin/adv_finalized/${id}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdvFinalizedIsClosed(id: string, is_closed: boolean, note: string, managed?: boolean ){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          is_closed: is_closed,
          note: note,
          managed: managed
        };
        console.log(data)
        this.http.patch(`${this.baseUrl}/admin/adv_finalized/is_closed/${id}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdvVisitor(id: string, managed: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          managed: managed,
        };
        this.http.patch(`${this.baseUrl}/admin/adv_visitor/${id}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  // ORDER
  
  getOrders(skip: number, limit: number, archivied?: boolean) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/order?skip=${skip}&limit=${limit}&archivied=${archivied}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOrdersWithTracker(imei: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/order/with_tracker/${imei}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchPrepareOrder(id_order: string, list_imei: string[]){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          list_imei: list_imei
        };
        this.http.patch(`${this.baseUrl}/admin/order/prepare_order/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchTrackingUrl(id_order: string, shipping_number: string, url_tracking: string, id_organization: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          shipping_number: shipping_number,
          url_tracking: url_tracking,
          id_organization,
        };
        this.http.patch(`${this.baseUrl}/admin/order/url_tracking/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchDeliveryNote(id_order: string, transportation: string, subject_transportation: string, n_packages: number, weight: number, port: string, transportation_note: string, date_start_shipping: number, delivery_client_info?: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          transportation,
          subject_transportation,
          n_packages,
          weight,
          port,
          transportation_note,
          date_start_shipping,
          delivery_client_info
        };
        this.http.patch(`${this.baseUrl}/admin/order/delivery_note/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchDeliveredStatus(id_order: string, received: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          received: received
        };
        this.http.patch(`${this.baseUrl}/admin/order/delivered_status/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchCancelOrder(id_order: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.patch(`${this.baseUrl}/admin/order/cancel/${id_order}`, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchNoteOrder(id_order: string, note: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data = {
          note: note
        }
        this.http.patch(`${this.baseUrl}/admin/order/note/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchDeleteImeiOrder(id_order: string, imei: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data = {
          imei: imei
        }
        this.http.patch(`${this.baseUrl}/admin/order/delete_imei/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTrackerInOrder(id_order: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/order/trackers/${id_order}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchHardCancelOrder(id_order: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(`${this.baseUrl}/admin/order/hard_cancel/${id_order}`, {}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  changeOrganizationCustomPrice(idOrganization: string, custom_price: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          custom_price: custom_price,
          id_organization: idOrganization
        };
        this.http.patch(`${this.baseUrl}/admin/stripe/organization/shop`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  changeOrganizationSubscriptionPlan(idOrganization: string, custom_plan: any, employee_email){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          custom_plan: custom_plan,
          id_organization: idOrganization,
          employee_email
        };
        this.http.patch(`${this.baseUrl}/admin/organization/subscription_price`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // FOTA
  
  getFotaConfigurations(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/teltonika/fota`, options).subscribe((res: any) => {
          ApiService.fota_api_key = res.api_key;
          ApiService.fota_api_url = res.api_url;
          ApiService.fota_company_id = res.company_id;
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  setFotaHeader(): HttpHeaders{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ApiService.fota_api_key}`
    })
    return headers;
  }
  
  getFotaTasksRiprogrammazione(imeis: string[], page: number): Promise<any[]>{
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let params: string = `page=${page}&per_page=100&sort=created_at&order=desc&type=TxConfiguration&type=TxFirmware&`
        for(let imei of imeis){
          params += `device_imei=${imei}&`;
        }
        this.http.get(`${ApiService.fota_api_url}/tasks?${params}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getFotaDevice(imei: string): Promise<any[]>{
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        this.http.get(`${ApiService.fota_api_url}/devices/${imei}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getFotaLastConfigTask(imei: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        this.http.get(`${ApiService.fota_api_url}/tasks?per_page=1&type=TxConfiguration&sort=created_at&order=desc&device_imei=${imei}`, options).subscribe((res: any) => {
          if(res?.data?.length > 0){
            resolve(res?.data[0]);
          }else{
            resolve()
          }
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postFotaGroup(group_name: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let params: any = {
          name: group_name,
          company_id: ApiService.fota_company_id
        }
        this.http.post(`${ApiService.fota_api_url}/groups`, params, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  deleteFotaGroup(group_id: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        this.http.delete(`${ApiService.fota_api_url}/groups/${group_id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postFotaUpdateDevicesGroup(imeis: string[], group_id: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let devices_imei: number[] = [];
        for(let imei of imeis){
          devices_imei.push(parseInt(imei));
        }
        let params: any = {
          source: "id_list",
          id_list: devices_imei,
          data: {
            group_id: group_id
          }
        }
        this.http.post(`${ApiService.fota_api_url}/devices/bulkUpdate`, params, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getFotaDevices(group_id: number): Promise<any[]>{
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        this.http.get(`${ApiService.fota_api_url}/devices?per_page=100&group_id=${group_id}`, options).subscribe((res: any) => {
          resolve(res?.data);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchCancelTasks(imeis: string[]){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let devices_imei: number[] = [];
        for(let imei of imeis){
          devices_imei.push(parseInt(imei));
        }
        let params: any = {
          source: "filter",
          filter: [
            {status_id: [0]},
            {device_imei: devices_imei}
          ],
          data: {
            status_id: 4
          }
        }
        this.http.post(`${ApiService.fota_api_url}/tasks/bulkUpdate`, params, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postCancelTask(task_id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let devices_imei: number[] = [];
        let params: any = {
          source: "filter",
          filter: [
            {status_id: [0]},
            {device_imei: devices_imei}
          ],
          data: {
            status_id: 4
          }
        }
        this.http.post(`${ApiService.fota_api_url}/tasks/${task_id}/cancel`, params, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchCreateTasksFirmware(imeis: string[], file_id: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let params: any = {
          source: "id_list",
          id_list: [],
          data: {
            type: "TxFirmware",
            file_id: file_id
          }
        }
        for(let imei of imeis){
          params.id_list.push(parseInt(imei));
        }
        this.http.post(`${ApiService.fota_api_url}/tasks/bulkCreate`, params, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchCreateTasksConfiguration(imeis: string[], file_id: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.setFotaHeader())
        };
        let params: any = {
          source: "id_list",
          id_list: [],
          data: {
            type: "TxConfiguration",
            file_id: file_id
          }
        }
        for(let imei of imeis){
          params.id_list.push(parseInt(imei));
        }
        this.http.post(`${ApiService.fota_api_url}/tasks/bulkCreate`, params, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchTrackerFotaInitialized(imei: string, data: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(`${this.baseUrl}/admin/teltonika/fota/programmed/${imei}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getStartReprogrammingTrackerGps(id_tracker: string, reprogramming_timestamp?: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}/admin/trackerGps/start_reprogramming/${id_tracker}`;
        if(reprogramming_timestamp) url += `?reprogramming_timestamp=${reprogramming_timestamp}`
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  startNewReport(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/analyze/organization`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOrganizationReport(report_id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/analyze/${report_id}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  // TWILIO
  
  getTwilioDeviceAccessToken(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/twilio/device_access_token`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTwilioCall(sid: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/twilio/call/${sid}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTwilioCalls(id_admins: string, skip: number, limit: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/twilio/admin/calls?id_admins=${id_admins},aaa&limit=${limit}&skip=${skip}`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAdminsListenerCalls(admin_id?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrlCommunication}/communication/admin/twilio/admin/forwarding`;
        if(admin_id){
          url += `?admin_id=${admin_id}`;
        }
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postCallRedirect(call_sid: string, admin_id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          call_sid: call_sid,
          final_admin_id: admin_id
        }
        this.http.post(`${this.baseUrlCommunication}/communication/admin/twilio/redirect_call`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdminStatus(is_busy: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {is_busy}
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/admin/status`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdminBotted(id_admin:string,important: boolean, delete_reserve?:boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {id_admin, important, delete_reserve}
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/admin/botted`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postAdminForwarding(id_admin_origin: string, id_admin_redirect: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {id_admin_origin, id_admin_redirect}
        this.http.post(`${this.baseUrlCommunication}/communication/admin/twilio/admin/forwarding`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTwilioAllCall(limit: number, skip: number, sort: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/twilio/admin/all_calls?limit=${limit}&skip=${skip}&sort=${
          sort
        }`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  updateTwilioClientIsOnline(in_call?: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.post(`${this.baseUrlCommunication}/communication/admin/twilio/client/is_online`, {in_call}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdminInCall(in_call: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {in_call};
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/admin/toggle/in_call`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdminRole(id_admin: string, role: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {id_admin, role};
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/admin/role`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchAdminReserveList(id_admin: string, admin_reserve_list: string[]){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {id_admin, admin_reserve_list};
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/admin/reserve`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  checkCallAssignment(platform_type: number, to_phone: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          platform_type, to_phone
        }
        this.http.post(`${this.baseUrlCommunication}/communication/admin/twilio/call_check_assignment`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postWebMessage(body: string, id_ticket: string, fileName?: string, firebasePath?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          message: body,
          id_ticket: id_ticket,
          filename: fileName,
          path_firebase: firebasePath,
        };
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/web_message/${id_ticket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  
  // TICKET
  postTicket(
    id_contact: string,
    platform_type: number,
    id_organization: string,
    type: number,
    title: string,
    trackers: any,
    trackers_replace: any,
    is_visible: boolean,
    important: boolean,
    sub_type: number,
    is_email: boolean) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {id_contact, platform_type, id_organization, type, title, trackers, trackers_replace, is_visible, important, sub_type, is_email}
        this.http.post(`${this.baseUrlCommunication}/communication/admin/ticket`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchTicketPinned(id_ticket: string, pinned: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {pinned};
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/ticket/pinned/${id_ticket}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  contactVerify(platform_type:number, phone?:number, email?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          platform_type,
          phone,
          email
        };
        this.http.post(`${this.baseUrlCommunication}/communication/admin/contact/verify`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getAssignment(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrlCommunication}/communication/admin/contact/assignments`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  postContact(name: string, company_name: string, platform_type: number, phone: string, emails: string[], sub_chat: any, id_company: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          name: name,
          company_name: company_name,
          phones: phone,
          platform_type: platform_type,
          emails: emails,
          sub_chat: sub_chat,
          id_company: id_company,
        };
        this.http.post(`${this.baseUrlCommunication}/communication/admin/contact/`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchContact(id_contact: string, name: string,  platform_type: number, phone: string, emails: string[], sub_chat: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let data = {
          name: name,
          phones: phone,
          platform_type: platform_type,
          emails: emails,
          sub_chat: sub_chat,
        };
        this.http.patch(`${this.baseUrlCommunication}/communication/admin/contact/${id_contact}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  patchTicket(idTicket: string, title: string, is_visible: boolean, note?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          title: title,
          is_visible: is_visible,
          note: note
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/ticket/${idTicket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchTicketType(idTicket: string, id_organization: string, type: number, sub_type: number, trackers: string[], trackers_replace: string[]) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          id_organization: id_organization,
          type: type,
          sub_type: sub_type,
          trackers: trackers,
          trackers_replace: trackers_replace
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/ticket/type/${idTicket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchTicketAdmin(idTicket: string, id_admin: string, important: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          id_admin: id_admin,
          important: important
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/ticket/change_admin/${idTicket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchTicketStatus(idTicket: string, status: TicketStatus) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          status: status
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/ticket/status/${idTicket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  confirmReplaceTracker(idTicket: string, amount: string, description: string, shipping: {name: string, address: string, city: string, cap: string, state: string, country: string, phone: string} ){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          amount: parseInt(amount) * 100,
          description,
          shipping: {
            name: shipping.name,
            address1: shipping.address,
            city: shipping.city,
            zipcode: shipping.cap,
            state: shipping.state,
            country: shipping.country,
            phone: shipping.phone,
          }
        };
        this.http.post(
          `${this.baseUrl}/admin/ticket/replace_tracker/${idTicket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchTicketContacts(idTicket: string, platform_type: number, phone_contacts: any, email_contacts: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          phone_contacts: phone_contacts,
          email_contacts: email_contacts,
          platform_type: platform_type
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/ticket/contacts/${idTicket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  getRecentShippingAddressByOrganizationId(id_organization: string){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/organization/recent_address/${id_organization}?limit=3`,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  createTicketFromMessageId(id_ticket: string, message_id: string, message_type: string){
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          id_ticket: id_ticket,
          message_id: message_id,
          message_type: message_type
        };
        this.http.post(
          `${this.baseUrlCommunication}/communication/admin/ticket/create-from-message`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchMessagesCountUnread(id_ticket: string, count_unread: number) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data: any = {
          count_unread_messages: count_unread,
        };
        
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/ticket/${id_ticket}`,
          data,
          options
        ).subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          return reject(err);
        });
      } catch(err) {
        return reject(err);
      }
    });
  }
  
  patchUnionContacts(id_target: string, id_destination: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.patch(
          `${this.baseUrlCommunication}/communication/admin/contact/union/${id_target}`
          , {destination_idContact: id_destination}
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      } catch(err) {
        reject(err);
      }
    });
  }
  
  
  archivieOrder(id_order: string, archivied: boolean){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data = {
          archivied: archivied
        }
        this.http.patch(`${this.baseUrl}/admin/order/archivie/${id_order}`, data, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  countUnmanagedVisitor(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(`${this.baseUrl}/admin/count_unmanaged_visitor`, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  generateToken(id_organization: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        const data = {
          id_organization: id_organization
        }
        this.http.post(`${this.baseUrl}/admin/token`, data ,options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  
  /** SIM ONOMONDO **/
  
  getSimOnomondo(sim_id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim/onomondo/${sim_id}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getSimsOnomondo(limit: number, offset: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        let url: string = `${this.baseUrl}/admin/sim/onomondo?limit=${limit}`;
        if(offset) url += `&offset=${offset}`
        
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchSimOnomondo(sim_id: string, label: string, activated: boolean, network?: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const patchBody = {
          label: label,
          activated: activated,
          network_whitelist: network,
        };
        
        this.http.patch(
          `${this.baseUrl}/admin/sim/onomondo/${sim_id}`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOnomondoCommandMultiple(sim_id: string, limit?: number, skip?: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim/onomondo/command/${sim_id}?limit=${limit}&skip=${skip}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postOnomondoCommand(sim_id: string, text: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const patchBody = {
          text: text
        };
        
        this.http.post(
          `${this.baseUrl}/admin/sim/onomondo/command/${sim_id}`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  /** SIM 1NCE **/

  getOnceSim(iccid_sim: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim/once/${iccid_sim}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchSimOnce(iccid: string, label: string, status: 'Enabled' | 'Disabled',  imei_lock?: boolean) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let patchBody: any = {
          label: label,
          status: status
        };
        
        if(imei_lock || imei_lock === false){
          patchBody.imei_lock = imei_lock;
        }
        
        this.http.patch(
          `${this.baseUrl}/admin/sim/once/${iccid}`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOnceSimUsageData(iccid_sim: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim/once/quota/data/${iccid_sim}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOnceSimUsageSms(iccid_sim: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim/once/quota/sms/${iccid_sim}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getOnceCommandMultiple(iccid: string, pageSize: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.get(
          `${this.baseUrl}/admin/sim/once/sims/sms/${iccid}?pageSize=${pageSize}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postSimOnceSms(iccid: string, payload: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let patchBody: any = {
          payload: payload
        };
        
        this.http.post(
          `${this.baseUrl}/admin/sim/once/sims/sms/${iccid}`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postSimOnceAutoTopup(iccids: string[], enabled: boolean) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let patchBody: any = {
          iccids: iccids,
          enabled: enabled
        };
        
        this.http.post(
          `${this.baseUrl}/admin/sim/once/sims/autoTopup/`
          , patchBody
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  /** SIM THINGSMOBILE **/
  
  getThingsmobileSim(iccid: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        this.http.post(`${this.baseUrl}/admin/sim/thingsmobile/status`, {iccid: iccid}, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postSimThingsmobileSms(iccid: string, message: string, encode?: boolean) {
    return new Promise(async (resolve, reject) => {
      if(encode){
        message = encodeURIComponent(message);
        message = decodeURIComponent(message);
      }
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let patchBody: any = {
          iccid: iccid,
          message: message
        };
        
        this.http.post(`${this.baseUrl}/admin/sim/thingsmobile/sms`, patchBody, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  /** API MIGRAZOINE **/
  
  /**
   * StartMigartion: api per avviare la migraizone degli utenti da trackingram a whitelabel
   * @param user_email
   * @param platform_type_to
   * @param new_subscription_price_id
   * @param trial_period_days
   */
  startMigration(user_email: string, platform_type_to: number, new_subscription_price_id: string, trial_period_days: number, trial_end_timestamp: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const data = {
          email: user_email,
          platform_type: platform_type_to,
          new_subscription_price_id: new_subscription_price_id,
          trial_period_days: trial_period_days,
          url_api_whitelabel: environment.baseUrl,
          trial_end_timestamp: trial_end_timestamp
        }
      
        this.http.post(
          `${this.baseUrlMigration}/migration/admin/v1/start_migration`,
          data,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getMigration(id_migration: string, id_user_trackingram?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/migration?id_migration=${id_migration}&id_user_trackingram=${id_user_trackingram}`,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getLastMigration(id_user_trackingram?: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/migration?id_user_trackingram=${id_user_trackingram}`,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getTrackingramUsers(skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/users?skip=${skip}&limit=${limit}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTrackingramUser(user_id: string) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/user/${user_id}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getTrackingramTrackers(skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/trackers?skip=${skip}&limit=${limit}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getTrackingramMigrationStatus(skip: number, limit: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/migration_status?skip=${skip}&limit=${limit}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getAvgTrackerPrice(user_id: string, discount?: number){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        let url: string = `${this.baseUrlMigration}/migration/admin/v1/tracker/avg_price/${user_id}`;
        if(discount) url += `?discount=${discount}`;
        this.http.get(
          url
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getTrackingramCustomerStripe(id_customer_stripe: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/stripe/${id_customer_stripe}`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getMigrationBatch(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/batch`
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  addMigrationBatch(emails: string[], name: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const data = {
          emails: emails,
          name: name
        }
        
        this.http.post(
          `${this.baseUrlMigration}/migration/admin/v1/batch`,
          data,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  deleteMigrationBatch(id_batch: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        this.http.delete(
          `${this.baseUrlMigration}/migration/admin/v1/batch/${id_batch}`,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchBatch(batch_id: string, configuration_price_type: number, configuration_price_id: string, configuration_sms_machine_admin_id: string, discount: number, migration_log?: any){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const data = {
          configuration_price_type: configuration_price_type,
          configuration_price_id: configuration_price_id,
          configuration_sms_machine_admin_id: configuration_sms_machine_admin_id,
          discount: discount,
          migration_log: migration_log
        }
        this.http.patch(
          `${this.baseUrlMigration}/migration/admin/v1/batch/${batch_id}`,
          data,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  getRestartMigrationBatchUsers(batch_id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        const data = {}
        this.http.patch(
          `${this.baseUrlMigration}/migration/admin/v1/batch/${batch_id}/get_restart_batch_user`,
          data,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getBatchDetails(batch_id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/batch/${batch_id}/single_batch_details`,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postCommandTrackingram(id_tracker: string, command: string, encode?: boolean) {
    return new Promise(async (resolve, reject) => {
      if(encode){
        command = encodeURIComponent(command);
        command = decodeURIComponent(command);
      }
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let patchBody: any = {
          id_tracker: id_tracker,
          command: command
        };
        
        this.http.post(`${this.baseUrlMigration}/migration/admin/send_trackingram_command`, patchBody, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  postCommandMigrationTrackingramByImei(imei: string, command_wind: string, command_thingsmobile: string, encode?: boolean) {
    return new Promise(async (resolve, reject) => {
      if(encode){
        command_wind = encodeURIComponent(command_wind);
        command_wind = decodeURIComponent(command_wind);
        command_thingsmobile = encodeURIComponent(command_thingsmobile);
        command_thingsmobile = decodeURIComponent(command_thingsmobile);
      }
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        let patchBody: any = {
          imei,
          command_wind,
          command_thingsmobile
        };
        
        this.http.post(`${this.baseUrlMigration}/migration/admin/send_trackingram_command_imei`, patchBody, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  
  getMigrationSoftwareInError(){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
      
        this.http.get(
          `${this.baseUrlMigration}/migration/admin/v1/user_migration_software_in_error`,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
  patchMigrationStatusManaged(migration_status_id: string){
    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          headers: <HttpHeaders> (await this.getFirebaseHeader())
        };
        
        const data = {}
        this.http.patch(
          `${this.baseUrlMigration}/migration/admin/v1/migration_status/${migration_status_id}`,
          data,
          options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }
  
}
