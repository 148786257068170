import {BillingType, Organization, BillingLocation} from "common-lib";
import {ListParsers} from "./listParsers";
import { Platform } from "./platform";
import Invoice from './invoice';
import {Subject} from 'rxjs';
import {MigrationStatus} from './migration';

type Options = { 
  $getPlatformByType?: (type: number) => Platform
  $getMigrationStatus?: (id: string) => MigrationStatus,
}

export type OrgProblemElement = {
  organization: OrganizationAdmin,
  selected: false,
  activities_to_do: boolean,
  no_payment_method: boolean,
  only_one_unpaid_invoice: boolean,
  only_one_unpaid_invoice_imminent_next_invoice: boolean,
  many_unpaid_invoices: boolean,
  unpaid_invoice: Invoice,
  unpaid_invoices: Invoice[],
  paused_subscription: boolean,
  trackers_to_deactivate: boolean,
  trackers_to_deactivate_list: any[],
  error_fattura24: boolean,
  error_fattura24_list: Invoice[],
  disputed_invoices: boolean,
  disputed_invoices_list: Invoice[],
  bank_transfert_to_check: boolean,
  bank_transfert_to_check_list: Invoice[],
  is_blocked: boolean,
  enable_balance_recharge: boolean,
  fatturazione_manuale: boolean,
  unpaid_invoices_fatturazione_manuale: Invoice[],
  last_dispute_invoice_list: Invoice[]
}

export class OrganizationAdmin extends Organization implements Options {

  raw: any;
  login_email_partecipant_owner: string;
  
  private _billing_type: BillingType;
  private _$billing_type_label: string;
  
  private _billing_location: BillingLocation;
  private _$billing_location_label: string;
  
  login_isInTrial: boolean;
  private _login_timestamp_trial_end: number;
  login_need_default_payment_method: boolean;
  login_delinquent: boolean;
  login_trackerGps_active: number;
  login_id_partecipant_owner: string;
  login_stripe_invoices_unpaid_count: number;
  login_stripe_invoices_unpaid_total: number;
  login_stripe_pause_collection: any;
  skip_payments_check: boolean;
  login_blocked: boolean;
  enable_balance_recharge: boolean;
  elenco_prodotti_shop: any[];
  subscription_price: any;
  $migration_status_lbl: any;
  migration_num_trackers: number;
  private _login_stripe_subscription_current_period_end: number;
  
  idTrackerGps: string;
  imei: string;
  idOrganization: string;
  idPartecipant: string;
  
  private _stripe_invoices: any;
  $disputed_invoices: any[];
  stripe_customer: any;
  stripe_subscription: any;
  private _stripe_payment_methods: any;
  stripe_upcoming_invoice: any;
  stripe_customer_id: string;
  stripe_disable_add_payment: boolean;
  stripe_disable_add_sepa: boolean;
  
  $trackers_imei: any[];
  
  static organizationUpdate = new Subject<OrganizationAdmin>();
  static organizationUpdate$ = OrganizationAdmin.organizationUpdate.asObservable();
  
  private _platform_type: number;
  $selected: boolean = false;
  stripe_timestamps_communication: { // type doesn't work properly
    [key: number]: number
  };
  $contacts: any[];
  private _$userId: any;
  private _$migration_status: any;
  private _$avg_price: any;
  
  $getPlatformByType: (type: number) => Platform = () => {return undefined};
  $getMigrationStatus: (id: string) => MigrationStatus = () => {return undefined};
  
  $platform: Platform;
  
  
  constructor(data: any, options?: Options) {
    super();
    this.$getPlatformByType = () => undefined;
    this.$getMigrationStatus = () => undefined;
    if (data) {
      this.setData(data, options);
    }
  }
  
  setData(data: any, options?: Options){
    super.setData(data);
    if (options) {
      if (options.$getPlatformByType) {
        this.$getPlatformByType = options.$getPlatformByType;
      }
      if(options.$getMigrationStatus){
        this.$getMigrationStatus = options.$getMigrationStatus;
      }
    }
    this.raw = data;
    this.login_email_partecipant_owner = data.login_email_partecipant_owner;
    this.login_isInTrial = data.login_isInTrial;
    this.login_timestamp_trial_end = data.login_timestamp_trial_end;
    this.login_need_default_payment_method = data.login_need_default_payment_method;
    this.login_delinquent = data.login_delinquent;
    this.login_trackerGps_active = data.login_trackerGps_active;
    this.login_id_partecipant_owner = data.login_id_partecipant_owner;
    this.login_stripe_invoices_unpaid_count = data.login_stripe_invoices_unpaid_count;
    this.login_stripe_invoices_unpaid_total = data.login_stripe_invoices_unpaid_total;
    this.login_stripe_pause_collection = data.login_stripe_pause_collection;
    this.login_stripe_subscription_current_period_end = data.login_stripe_subscription_current_period_end;
    this.platform_type = data.platform_type;
    this.stripe_timestamps_communication = data.stripe_timestamps_communication;
    this.stripe_invoices = data.stripe_invoices;
    this.idTrackerGps = data.idTrackerGps
    this.imei = data.imei
    this.idOrganization = data.idOrganization
    this.idPartecipant = data.idPartecipant;
    this.skip_payments_check = data.skip_payments_check;
    this.login_blocked = data.login_blocked;
    this.stripe_customer_id = data.stripe_customer_id;
    this.stripe_customer = data.stripe_customer;
    this.stripe_subscription = data.stripe_subscription;
    this.stripe_payment_methods = data.stripe_payment_methods;
    this.stripe_upcoming_invoice = data.stripe_upcoming_invoice;
    this.stripe_subscription = data.stripe_subscription;
    this.enable_balance_recharge = data.enable_balance_recharge;
    this.elenco_prodotti_shop = data.elenco_prodotti_shop;
    this.subscription_price = data.subscription_price;
    this.$migration_status_lbl = data.migration_status;
    this.migration_num_trackers = data.migration_num_trackers;
    this.stripe_disable_add_payment = data.stripe_disable_add_payment;
    this.stripe_disable_add_sepa = data.stripe_disable_add_sepa;
  
    this.$userId = data._id;
    OrganizationAdmin.organizationUpdate.next(this); // Ogni volta che viene fatto un set data lancio un evento generale così chi vuole può aggiornare il riferimento alla organizzazione
  }
  
  get $avg_price(): any {
    return this._$avg_price;
  }
  
  set $avg_price(value: any) {
    this._$avg_price = value;
  }
  
  get $userId(): any {
    return this._$userId;
  }
  
  set $userId(value: any) {
    this._$userId = value;
    if(this.$userId){
      this.$migration_status = this.$getMigrationStatus(this.$userId)
    }
  }
  
  get $migration_status(): any {
    return this._$migration_status;
  }
  
  set $migration_status(value: any) {
    this._$migration_status = value;
  }
  
  
  get billing_type(): 1 | 2 {
    return this._billing_type;
  }
  
  set billing_type(value: 1 | 2) {
    this._billing_type = value;
    this.$billing_type_label = ListParsers.parseBillingType(this.billing_type as number);
  }
  
  get $billing_type_label(): string {
    return this._$billing_type_label;
  }
  
  set $billing_type_label(value: string) {
    this._$billing_type_label = value;
  }
  
  get billing_location(): 1 | 2 | 3 {
    return this._billing_location;
  }
  
  set billing_location(value: 1 | 2 | 3) {
    this._billing_location = value;
    this.$billing_location_label = ListParsers.parseBillingLocations(this.billing_location as number);
  }
  
  get $billing_location_label(): string {
    return this._$billing_location_label;
  }
  
  set $billing_location_label(value: string) {
    this._$billing_location_label = value;
  }
  
  get login_timestamp_trial_end(): number {
    return this._login_timestamp_trial_end;
  }
  
  set login_timestamp_trial_end(value: number) {
    this._login_timestamp_trial_end = value ? value * 1000 : value;
  }
  
  get login_stripe_subscription_current_period_end(): number {
    return this._login_stripe_subscription_current_period_end;
  }
  
  set login_stripe_subscription_current_period_end(value: number) {
    this._login_stripe_subscription_current_period_end = value ? value * 1000 : value;
  }
  
  get stripe_invoices(): Invoice[]{
    return this._stripe_invoices;
  }
  
  set stripe_invoices(invoices: Invoice[]) {
    this._stripe_invoices = [];
    if(!invoices) return;
    if(!Array.isArray(invoices)){
      invoices = Object.values(invoices); // Nuovo oggetto organization e non più in array
    }
    for(let invoice of invoices){
      this._stripe_invoices.push(new Invoice(invoice));
    }
    this.updateDisputedInvoices();
  }
  
  private updateDisputedInvoices(){
    if(this._stripe_invoices?.length >= 0){
      this.$disputed_invoices = [];
      for(let invoice of this._stripe_invoices){
        if(invoice.charge?.disputed){
          this.$disputed_invoices.push(invoice);
        }
      }
    }
  }
  
  
  get stripe_payment_methods(): any {
    return this._stripe_payment_methods;
  }
  
  set stripe_payment_methods(value: any) {
    if (!value) return;
    if (!Array.isArray(value)) {
      value = Object.values(value); // Nuovo oggetto organization e non più in array
    }
    this._stripe_payment_methods = value;
  }
  
  public get platform_type(): number {
    return this._platform_type;
  }
  public set platform_type(value: number) {
    this._platform_type = value;
    // * set subfields
    // this.$platform = DataService.general_parameter?.array_platform?.find(el => el.type === value)
    this.$platform = this.$getPlatformByType(value);
  }
  
  public updateInvoice(id: string, data: any): Invoice{
    if(!id) return;
    const invoice: Invoice = this.stripe_invoices.find((i: Invoice) => i._id === id);
    if(invoice){
      invoice.setData(data);
      return invoice;
    }
  }
  
  public getInvoiceByIdStripe(id: string): Invoice{
    if(this.stripe_invoices){
      return this.stripe_invoices.find((elem: Invoice) => elem._id === id);
    }
    return null;
  }
  
}

