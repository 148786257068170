export const environment = {
  production: true,
  baseUrl: 'https://default-produzione-acngrn4ciq-ew.a.run.app',
  // baseUrl: 'http://localhost:8080',
  baseUrlCommunication: 'https://comunication-produzione-acngrn4ciq-ew.a.run.app',
  baseUrlMigration: 'https://migrazione-trackingram-backend-prod-acngrn4ciq-ew.a.run.app',
  url_admin_nuovo: "https://admin2.balin.app",
  firebaseConfig: {
    apiKey: "AIzaSyBjE-8j-0Ct-XZytEKdAHjxyFCl9uMo2SA",
    authDomain: "white-label-prod.firebaseapp.com",
    databaseURL: "https://white-label-prod-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "white-label-prod",
    storageBucket: "white-label-prod.appspot.com",
    messagingSenderId: "336855160915",
    appId: "1:336855160915:web:65aa5ebe477f89ab274ea6",
    measurementId: "G-GCP6NC3J4Q"
  }
};
